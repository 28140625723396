<template>
    <div class="contain" :style="wHeight">
        
        <div class="icon">
            <img src="@/assets/image/tapdance/icon.png"/>
        </div>

        <!-- <img src="@/assets/image/ic_logo_right.png" class="title_right_qz"/> -->

        <van-row justify="center">
            <van-col span="10" class="title">{{ $t("tapdance.share.title")}}</van-col>
        </van-row>

        <div class="content">
            <div>
                {{ $t("tapdance.share.content.one")}}
            </div>
            <!-- <div class="content_two">
                {{ $t("tetris.share.content.two")}}
            </div> -->
        </div>

        <!-- <img src="@/assets/image/ic_left_bottom.png" class="left_ic" />

        <img src="@/assets/image/ic_fly.png" class="ic_fly"/>
        <img src="@/assets/image/ic_left_qz.png" class="left_qz"/> -->
       

        <div class="btn_div">
            <van-row >
                <van-col offset="12" span="10" class="share_btn" @click="showDialog">
                    <img src="@/assets/image/icon_apple.png"  />
                    <div>
                        <div class="icon_title">IOS</div>
                        <div>Apple Store</div>
                    </div>
                </van-col>
            </van-row>

            <van-row>
                <van-col offset="12" span="10" class="share_btn" @click="toGooglePlay">
                    <img src="@/assets/image/icon_play.png" />
                    <div>
                        <div class="icon_title">Android</div>
                        <div>Google Play</div>
                    </div>
                </van-col>
            </van-row>
            
            <van-row>
                <van-col offset="12" span="10" class="share_btn" @click="toDownload">
                    <img src="@/assets/image/icon_android.png"  />
                    <div>
                        <div class="icon_title">Android</div>
                        <div>{{$t("share.download")}}</div>
                    </div>
                </van-col>
            </van-row>

            <van-row>
                <van-col offset="12" span="10" class="online" @click="toOnline">
                    {{ $t("share.online")}}
                </van-col>
            </van-row>
        </div>

        <div class="right_qz_div">
            <img src="@/assets/image/ic_left_qz.png" style="visibility:hidden;" class="right_qz" />
        </div>

        <van-dialog v-model:show="show" style="width: 18.75rem;background: transparent;" :showConfirmButton="false" :overlay="false">
            <!-- <img src="https://fastly.jsdelivr.net/npm/@vant/assets/apple-3.jpeg" /> -->
            <div class="dialog">
                <div class="dialog_tip">{{$t("share.tips")}}</div>
                <img src="@/assets/image/icon_close.png" class="dialog_close" @click="dismissDialog"/>
                <div class="dialog_content">{{$t("share.tipsContent")}}</div>
            </div>
        </van-dialog>

        <img src="@/assets/image/tetris/top_icon.png" class="topIcon"/>

    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data(){
        return {
            wHeight: "",
            show:false
        }
    },
    mounted () {
        // var clientHeight = 0;
        // if (document.body.clientHeight && document.documentElement.clientHeight) {
        //     clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
        // }
        // else {
        //     clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
        // }
        // this.wHeight = `height:${clientHeight}px`
    },
     methods: {
         toGooglePlay(){
             window.location.href ="https://play.google.com/store/apps/details?id=com.kelemetaverse.tapdance"
         },
         dismissDialog(){
            this.show = false
         },
         showDialog(){
            this.show = true
         },
         toDownload(){
             window.location.href = "https://download.kelemetaverse.com/tapdance_app_release_1.0.0.apk"
         },
         toOnline () {
             window.location.href = "https://tapdance.kelemetaverse.com/#/"
         }
         
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contain{
    width: 100%;
    /* height: 300vh; */
    /* background: -webkit-linear-gradient(top, #e3cea1, #e1b6ae, #e4afb8); */
    /* background: url("../assets/image/bg.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed; */
    background: url("../../../assets/image/tapdance/bg.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
}

.dialog{
    background-image: url("../../../assets/image/bg_dialog.png");
    background-size: 100% 100%;
    width: 18.75rem;
    height:9.375rem;
    color: black;
}

.icon{
    position: absolute;
    top: 1.5625rem;
    left: 50%;
    margin-left: -3.6rem;
}

.icon img{
    width: 7.5rem;
    height: 7.5rem;
}

.title_right_qz {
    position: absolute;
    width: 2.8125rem;
    height: 2.1875rem;
    right: 5.3125rem;
    top: 4.6875rem;
}

.dialog_close{
    position: absolute;
    right: 1.25rem;
    width: 1.5625rem;
    height: 1.5625rem;
    top: 1.25rem;
}

.title{
    color:white;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 10rem;
}

.dialog_tip{
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    padding-top: 1.25rem;
}

.dialog_content{
    text-align: center;
    font-size: 1rem;
    margin-top: .625rem;
}

.content {
    background-color: white;
    opacity: .7;
    margin: 0rem 1.25rem;
    margin-top: .625rem;
    padding: .75rem;
    font-size: 1.0625rem;
    border-radius: .625rem;
    padding-bottom: .75rem;
    z-index: 100;
    margin-bottom: 8.25rem;
}

.content_two{
    margin-top: 1.25rem;
}

.share_btn{
    background-color: white;
    border-radius: 3.125rem;
    display: flex;
    flex-direction: row;
    padding:.375rem 0rem;
    padding-left: 1.25rem;
    font-size: .875rem;
    align-items: center;
    margin-bottom: 1.875rem;
}

.online {
    height: 2.5rem;
    text-align: center;
    background-color: white;
    border-radius: 3.125rem;
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
    color: #cd4811;
}

.share_btn img{
    margin-right: .625rem;
    width: 1.75rem;
    height: 1.75rem;
}

.ic_fly{
    position:absolute;
    width: 8.125rem;
    height: 8.125rem;
    right: 0;
    margin-top: -5rem;
}

.icon_title{
    font-size: .75rem;
    color: #aaa;
}

.btn_div{
    position: absolute;
    /* bottom: 0.5rem; */
    margin-top: 2rem;
    width: 100%;
    z-index: 99;
}

.left_ic{
    width: 15.625rem;
    height: 18.75rem;
    position:absolute;
    z-index: 10;
}

.left_qz{
    position:absolute;
    left: 1.25rem;
    width: 2.8125rem;
    height: 3.4375rem;
    margin-top: -3.4375rem;
}

.right_qz{
    /* position: absolute; */
    right: .625rem;
    /* bottom: .625rem; */
    width: 2.1875rem;
    height: 2.8125rem;
    margin-top: 17.1875rem;
    text-align: right;
}

.topIcon{
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
}

.right_qz_div{
    text-align: right;
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
    .content {
        z-index: 100;
        background-color: white;
        opacity: .7;
        margin: 0rem 1.25rem;
        margin-top: .625rem;
        padding: .75rem;
        font-size: .8125rem;
        border-radius: .625rem;
        padding-bottom: .75rem;
        margin-bottom: 4rem;
    }

    .icon {
        position: absolute;
        top: 1.25rem;
        left: 50%;
        margin-left: -3.6rem;
    }

    .title {
        color: white;
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
        margin-top: 8.125rem;
    }

    .title_right_qz {
        position: absolute;
        width: 2.8125rem;
        height: 2.1875rem;
        right: 5.3125rem;
        top: 4.375rem;
    }
}
</style>
